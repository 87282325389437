<template>
	<div class="opacity-page">
		<div class="header-filter" v-if="loaded">
			<div class="row-space-tbf search-row">
				<div class="space-left"><icon-search v-if="$resize && $mq.above(781)" /></div>
				<div class="content">
					<div class="search-input">
						<input type="text" :placeholder="$t('projects.search_by_name')" v-model="search_filter" v-debounce:500="searchFilterFunct" />
					</div>

					<div class="actions-page mobile">
						<button v-if="$resize && $mq.above(600)" class="btn-tbf grey filter-btn" v-bind:class="{ active: extendFilters }" @click="extendFilters = !extendFilters">
							<div class="counter" v-bind:class="{ active: queryFiltersCount > 0 }">{{ queryFiltersCount }}</div>
							<span class="text">{{ $t("general.filters") }}</span>
							<div class="icon-filter"><icon-arrow /></div>
						</button>
						<button v-else-if="$resize && $mq.below(599)" class="btn-tbf grey filter-btn-mobile" v-bind:class="{ active: extendFilters }" @click="extendFilters = !extendFilters">
							<div class="icon settings"><icon-settings /></div>
						</button>

						<button class="btn-tbf blue" v-if="$auth.user().rights.can_create_project" @click="createProject()">
							<div class="icon">
								<icon-plus />
							</div>
							<span v-if="$resize && $mq.above(600)" class="text">{{ $t("projects.create-btn") }}</span>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
			<div class="row-space-tbf filters-extend" :class="[{ mobile: $resize && $mq.below(1120) }]" v-if="extendFilters">
				<div class="space-left"></div>
				<div class="content users">
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedUsers.length">{{ $t("filters.by_user") }}</span>
							<span class="text" v-else-if="selectedUsers.length == 1">{{ filterUsers.find((el) => el.id == selectedUsers[0]).name }}</span>
							<span class="text" v-else>{{ selectedUsers.length }} {{ $t("filters.users_selected") }}</span>

							<div class="icon-filter" v-if="!selectedUsers.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedUsers')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchUsers" :placeholder="$t('filters.search_users')" />
							</div>

							<template v-for="user in filteredUsers">
								<div
									class="dropdown-item"
									@click.stop="selectOptionFilter('selectedUsers', user.id)"
									v-bind:class="{ active: selectedUsers.includes(user.id) }"
									:key="'filter-user-' + user.id"
								>
									<div class="checkbox">
										<div class="checkmark"></div>
										<span class="text">{{ user.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>
					<div class="filter-dropdown dropdown">
						<button class="btn-tbf white" id="dropdownFilter" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							<span class="text" v-if="!selectedDepartments.length">{{ $t("filters.by_departments") }}</span>
							<span class="text" v-else-if="selectedDepartments.length == 1">{{ filterDepartments.find((el) => el.id == selectedDepartments[0]).name }}</span>
							<span class="text" v-else>{{ selectedDepartments.length }} {{ $t("filters.departments_selected") }}</span>

							<div class="icon-filter" v-if="!selectedDepartments.length"><icon-arrow /></div>
							<div class="icon-clear" @click.stop="clearFilter('selectedDepartments')" v-else><icon-close /></div>
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownFilter">
							<div class="search-filter-dropdown">
								<input type="text" v-model="searchDepartments" :placeholder="$t('filters.search_department')" />
							</div>

							<template v-for="department in filteredDepartments">
								<div
									class="dropdown-item"
									@click.stop="selectOptionFilter('selectedDepartments', department.id)"
									v-bind:class="{ active: selectedDepartments.includes(department.id) }"
									:key="'filter-department-' + department.id"
								>
									<div class="checkbox" :class="`level-${department.level_depth}`" :style="`padding-left: calc(10px * ${department.level_depth});`">
										<div class="checkmark"></div>
										<span class="text">{{ department.name }}</span>
									</div>
								</div>
							</template>
						</div>
					</div>

					<button class="btn-tbf white clear-filters" @click.stop="clearFilter('all')">
						<icon-trash v-if="$resize && $mq.above(1121)" />
						<div v-else-if="$resize && $mq.below(1120)">{{ $t("filters.clear_all") }}</div>
					</button>
				</div>
				<div class="space-right"></div>
			</div>
		</div>
		<loader-header-list v-else />

		<template v-if="loadedList">
			<div class="data-list list-projects" v-if="projects.length">
				<div class="row-space-tbf header-count-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="text-list-length">
							{{ $t("general.list_length") }} <span class="number">{{ totalResults }}</span>
						</div>
					</div>
					<div class="space-right"></div>
				</div>

				<div class="row-space-tbf header-list">
					<div class="space-left"></div>
					<div class="content">
						<div class="column-filter sortable column-name" @click="sortList('name')" v-bind:class="[sortBy == 'name' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t("projects.project_name") }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(501)" class="column-filter column-users">
							<div class="text">{{ $t("projects.contributors") }}</div>
						</div>
						<div
							v-if="$resize && $mq.above(1120)"
							class="column-filter sortable column-tasks"
							@click="sortList('total_tasks')"
							v-bind:class="[sortBy == 'total_tasks' ? 'sort ' + sortDirection : '']"
						>
							<div class="text">{{ $t("projects.count_tasks") }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div
							v-if="$resize && $mq.above(580)"
							class="column-filter sortable column-last-update"
							@click="sortList('updated_at')"
							v-bind:class="[sortBy == 'updated_at' ? 'sort ' + sortDirection : '']"
						>
							<div class="text">{{ $t("general.last_update") }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div v-if="$resize && $mq.above(1000)" class="column-filter column-last-update">
							<div class="text">{{ $t("meeting_session.deadline") }}</div>
						</div>
						<div class="column-filter sortable column-progress" @click="sortList('progress')" v-bind:class="[sortBy == 'progress' ? 'sort ' + sortDirection : '']">
							<div class="text">{{ $t("projects.progress") }}</div>
							<div class="icon-filter"><icon-arrow /></div>
						</div>
						<div class="column-filter column-user" v-if="$resize && $mq.above(985)"></div>

						<div class="column-filter column-edit-mobile" v-if="$resize && $mq.below(500)"></div>
					</div>
					<div class="space-right"></div>
				</div>
				<template v-for="project in projects">
					<div class="row-space-tbf row-list-item" :key="project.id">
						<div class="space-left"><icon-projects v-if="$resize && $mq.above(781)" /></div>
						<div class="content">
							<div class="border-hover" v-if="$resize && $mq.above(781)"></div>
							<div class="column-simple-text column-name link" @click="project.rights.editor ? editProject(project.slug) : showProject(project)">
								<div class="text" v-html="project.name"></div>
							</div>
							<div v-if="$resize && $mq.above(501)" class="column-simple-text column-users">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" v-if="project.users.length">
									<div class="users-avatars">
										<div class="image" v-for="user in project.users.slice(0, 3)">
											<img :src="user.avatar" />
										</div>
										<div class="image plus-icon" v-if="project.users.length > 3">
											<icon-plus />
											<span class="numer">{{ project.users.length - 3 }}</span>
										</div>
									</div>
									<template slot="popover">
										<div class="simple-text">
											{{
												project.users
													.map((el) => {
														return el.name;
													})
													.join(", ")
											}}
										</div>
									</template>
								</v-popover>
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" v-else>
									<div class="users-avatars">
										<div class="image not-users">
											<icon-no-user />
										</div>
									</div>
									<template slot="popover">
										<div class="simple-text">{{ $t("projects.not_users_for_this_project_small") }}</div>
									</template>
								</v-popover>
							</div>
							<div v-if="$resize && $mq.above(1120)" class="column-simple-text column-tasks">
								<div class="text">{{ project.tasks }}</div>
							</div>
							<div v-if="$resize && $mq.above(580)" class="column-simple-text column-last-update">
								<div class="text">{{ project.updated_at | moment("DD MMM YYYY") }}</div>
							</div>
							<div v-if="$resize && $mq.above(1000)" class="column-simple-text column-last-update">
								<div class="text">{{ project.project_task_deadline | moment("DD MMM YYYY") }}</div>
							</div>
							<div class="column-progress column-value-progress">
								<div class="top-data">
									<div class="current-value">
										<div class="value">{{ parseInt(project.progress).toString() + "%" }}</div>
									</div>
								</div>
								<div class="progress-bar-tbf" v-bind:class="{ disabled: project.progress == 0 }">
									<div
										class="complete-progress-tbf"
										v-bind:class="{ green: project.progress == 100 }"
										:style="'width: calc(2px + ' + (parseInt(project.progress) < 1 ? '2px' : project.progress + '%') + ');'"
									></div>
								</div>
							</div>

							<div class="column-avatar column-user" v-if="$resize && $mq.above(985)">
								<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" :delay="{ show: 200, hide: 0 }" :disabled="!project.responsible">
									<img :src="project.responsible.avatar" v-if="project.responsible && project.responsible.avatar" />
									<div class="user-circle" v-else>
										<icon-user />
									</div>
									<template slot="popover">
										<div class="simple-text">{{ project.responsible ? project.responsible.name : "" }}</div>
									</template>
								</v-popover>
							</div>

							<div class="column-edit-mobile" v-if="$resize && $mq.below(500)">
								<div class="dropdown dropdown-edit">
									<div class="button-edit" :ref="'dropdownEditRef' + project.id" :id="'dropdownEdit' + project.id" data-toggle="dropdown">
										<icon-edit-mobile />
									</div>
									<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu' + project.id" :aria-labelledby="'dropdownEdit' + project.id">
										<div class="dropdown-item" @click="showProject(project)">
											<div class="simple-text">{{ $t("general.view") }}</div>
										</div>
										<div class="dropdown-item" @click="editProject(project.slug)" v-if="project.rights.editor">
											<div class="simple-text">{{ $t("general.edit") }}</div>
										</div>
										<div class="dropdown-item" @click="showModal('delete', { type: 'project', from: 'index_projects', model: project })" v-if="project.rights.edit_delete">
											<div class="simple-text">{{ $t("general.delete") }}</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="space-right">
							<div v-if="$resize && $mq.above(501)" class="dropdown dropdown-edit">
								<div class="button-edit" :ref="'dropdownEditRef' + project.id" :id="'dropdownEdit' + project.id" data-toggle="dropdown">
									<icon-edit />
								</div>
								<div class="dropdown-menu dropdown-menu-right" :id="'dropdownMenu' + project.id" :aria-labelledby="'dropdownEdit' + project.id">
									<div class="dropdown-item" @click="showProject(project)">
										<div class="simple-text">{{ $t("general.view") }}</div>
									</div>
									<div class="dropdown-item" @click="editProject(project.slug)" v-if="project.rights.editor">
										<div class="simple-text">{{ $t("general.edit") }}</div>
									</div>
									<div class="dropdown-item" @click="showModal('delete', { type: 'project', from: 'index_projects', model: project })" v-if="project.rights.edit_delete">
										<div class="simple-text">{{ $t("general.delete") }}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row-space-tbf list-empty" v-else>
				<div class="space-left"></div>
				<div class="content full">
					<!-- <div class="icon-empty">
						<img src="/build/images/no-results-found.svg">
					</div> -->
					<div class="title">{{ $t("empty.title") }}</div>
					<div class="description" v-html="$t('empty.projects_text')"></div>

					<div class="add-button" v-if="$auth.user().rights.can_create_project">
						<button class="btn-tbf blue center" @click="createProject()">
							<div class="icon"><icon-plus class="white" /></div>
							<div class="text">{{ $t("projects.create-btn") }}</div>
						</button>
					</div>
				</div>
				<div class="space-right"></div>
			</div>
		</template>
		<loader-items-list class="position-absolut-list-loader" v-else />

		<infinite-loading :identifier="infiniteId" @infinite="infiniteProjectsHandler" ref="infiniteLoading"
			><div slot="spinner"></div>
			<div slot="no-more"></div>
			<div slot="no-results"></div
		></infinite-loading>
	</div>
</template>

<script>
import IconSearch from "../../Icons/Search";
import IconPlus from "../../Icons/Plus";
import IconArrow from "../../Icons/Arrow";
import IconProjects from "../../Icons/Projects";
import IconEdit from "../../Icons/EditDots";
import IconEditMobile from "../../Icons/Edit";
import IconClose from "../../Icons/Close";
import LoaderItemsList from "../../PagesLoaders/ItemsList";
import LoaderHeaderList from "../../PagesLoaders/HeaderList";
import InfiniteLoading from "vue-infinite-loading";
import IconNoUser from "../../Icons/NoUser";
import IconList from "../../Icons/List";
import IconTrash from "../../Icons/Trash";
import IconUser from "@/components/Icons/User";

export default {
	components: {
		IconSearch,
		IconPlus,
		IconArrow,
		IconProjects,
		IconEdit,
		IconEditMobile,
		IconClose,
		LoaderItemsList,
		LoaderHeaderList,
		IconNoUser,
		InfiniteLoading,
		IconList,
		IconTrash,
		IconUser,
	},
	data() {
		return {
			loaded: false,
			loadedList: false,
			search_filter: "",
			projects: [],
			sortBy: "",
			sortDirection: "asc",
			page: 0,
			infiniteId: 1,
			totalResults: 0,
			queryFiltersCount: 0,
			extendFilters: true,
			filterUsers: [],
			selectedUsers: [],
			searchUsers: "",
			filterDepartments: [],
			selectedDepartments: [],
			searchDepartments: "",
		};
	},
	beforeDestroy() {
		this.$root.$off("refreshProjectsIndex");
	},
	computed: {
		filteredUsers() {
			return getByKeywordFilter(this.filterUsers, this.searchUsers);
		},
		filteredDepartments() {
			return getByKeywordFilter(this.filterDepartments, this.searchDepartments);
		},
	},
	async mounted() {
		this.$refs.infiniteLoading.status = 1;
		this.$refs.infiniteLoading.$emit("infinite", this.$refs.infiniteLoading.stateChanger);

		await this.getFilters();
		await this.checkQueryFilters();

		setTimeout(() => {
			var title = this.$t("general.projects");
			this.$root.$emit("navbar_title", title);
		}, 0);

		this.$root.$on("refreshProjectsIndex", () => {
			this.searchFilterFunct();
		});
	},
	watch: {
		search_filter: function(val) {
			var data = Object.assign({}, this.$route.query);
			data["search"] = this.search_filter;

			if (this.search_filter == "") {
				delete data["search"];
			}

			this.$router.push({ query: data });
		},
	},
	methods: {
		async getFilters() {
			await axios
				.get(`instances/${this.$auth.user().instance.id}/filter`, {
					params: {
						users: true,
						departments_level: true,
					},
				})
				.then(({ data }) => {
					this.filterDepartments = data.data.departments_level;
					this.filterUsers = data.data.users;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					this.loaded = true;
				});
		},
		infiniteProjectsHandler($state) {
			var paramsCall = { view_more: this.page * 20 };
			paramsCall = this.queryObject(paramsCall);

			if (this.sortBy) {
				paramsCall.sort_by = this.sortBy;
				paramsCall.sort_direction = this.sortDirection;
			}

			if (this.$cookies.get("filters_project")) {
				var cookiesData = this.$cookies.get("filters_project");
				paramsCall.sort_by = cookiesData.sort_by;
				paramsCall.sort_direction = cookiesData.sort_direction;
			}

			axios
				.get(this.$auth.user().instance.id + "/projects", { params: paramsCall })
				.then(({ data }) => {
					this.totalResults = data.total_results;
					if (data.data.length) {
						this.page++;
						this.projects.push(...data.data);
						setTimeout(() => {
							$state.loaded();
						}, 200);
						if (data.data.length < 20) {
							$state.complete();
						}
					} else {
						$state.complete();
					}
					this.loadedList = true;
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 500) {
							alert(this.$t("error.500"));
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true;
						setTimeout(() => {
							$(".opacity-page").addClass("show");
						}, 0);
					}, 0);
				});
		},
		clearFilter(filter) {
			if (filter == "all") {
				this.selectedUsers = [];
				this.selectedDepartments = [];
			} else {
				this[filter] = [];
			}

			this.addFilterToRouteQuery(filter);
		},
		queryObject(paramsCall) {
			var dataQuery = Object.assign({}, this.$route.query);

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					paramsCall.search = dataQuery[key];
				}
				if (key == "users") {
					paramsCall.user_ids = dataQuery[key].split(",").map(Number);
				}
				if (key == "departments") {
					paramsCall.department_ids = dataQuery[key].split(",").map(Number);
				}
				if (key == "sortBy") {
					paramsCall.sort_by = dataQuery[key];
				}
				if (key == "sortDirection") {
					paramsCall.sort_direction = dataQuery[key];
				}
			});

			return paramsCall;
		},
		selectOptionFilter(filter, value) {
			if (typeof value == "string") {
				if (this[filter].includes(value)) {
					this[filter].splice(this[filter].indexOf(value), 1);
				} else {
					this[filter].push(value);
				}
			} else {
				if (this[filter].includes(parseInt(value))) {
					this[filter].splice(this[filter].indexOf(parseInt(value)), 1);
				} else {
					this[filter].push(parseInt(value));
				}
			}

			this.addFilterToRouteQuery(filter);
		},
		addFilterToRouteQuery(filterName) {
			var dataQuery = Object.assign({}, this.$route.query);

			if (filterName == "selectedUsers" || filterName == "all") {
				dataQuery["users"] = this.selectedUsers.toString();
			}
			if (filterName == "selectedDepartments" || filterName == "all") {
				dataQuery["departments"] = this.selectedDepartments.toString();
			}
			if (["name", "total_tasks", "updated_at", "progress"].includes(filterName)) {
				dataQuery["sortBy"] = filterName;
				dataQuery["sortDirection"] = this.sortDirection;
			}

			Object.keys(dataQuery).map(function(key, value) {
				if (dataQuery[key] == "") {
					delete dataQuery[key];
				}
			});

			this.queryFiltersCount = this.search_filter != "" ? Object.keys(dataQuery).length - 1 : Object.keys(dataQuery).length;

			this.$router.push({ query: dataQuery });
			this.searchFilterFunct();
		},
		checkQueryFilters() {
			var dataQuery = Object.assign({}, this.$route.query);

			if (this.$cookies.get("filters_project")) {
				var cookiesData = this.$cookies.get("filters_project");
				this.sortBy = cookiesData.sort_by;
				this.sortDirection = cookiesData.sort_direction;
			}

			Object.keys(dataQuery).forEach((key) => {
				if (key == "search") {
					this.search_filter = dataQuery[key];
				}
				if (key == "users") {
					this.selectedUsers = dataQuery[key].split(",").map(Number);
				}
				if (key == "departments") {
					this.selectedDepartments = dataQuery[key].split(",").map(Number);
				}
				if (key == "sortBy") {
					this.sortBy = dataQuery[key];
				}
				if (key == "sortDirection") {
					this.sortDirection = dataQuery[key];
				}
			});
		},
		sortList(column) {
			var paramsToCookies = {};

			if (column === this.sortBy) {
				this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
			}

			this.sortBy = column;

			paramsToCookies.sort_by = column;
			paramsToCookies.sort_direction = this.sortDirection;

			this.$cookies.set("filters_project", JSON.stringify(paramsToCookies), "30d");

			this.addFilterToRouteQuery(column);

			// this.searchFilterFunct();
		},
		showProject(project) {
			this.$router.push({ name: "projects-show", params: { slug: project.slug } });
		},
		editProject(projectSlug) {
			this.$router.push({ name: "projects-edit", params: { slug: projectSlug } });
		},
		showModal(type, data = false, itemId = false) {
			this.$root.$emit("open_modal", type, data, itemId);
		},
		searchFilterFunct() {
			this.loadedList = false;
			this.page = 0;
			this.projects = [];
			this.infiniteId += 1;
		},
		createProject() {
			axios.post("/projects/store").then(({ data }) => {
				this.editProject(data.data.slug);
			});
		},
	},
};

function getByKeywordFilter(list, keyword) {
	const search = keyword.trim();

	if (!search.length) return list;
	return list.filter((item) => item.name.toLowerCase().indexOf(search.toLowerCase()) > -1);
}
</script>

<style lang="scss" scoped>
.list-projects {
	.row-list-item {
		&:hover {
			.content {
				.column-name {
					.text {
						font-weight: 700;
					}
				}
			}
		}
		.content {
			.users-avatars {
				.image {
					border: 1px solid lightgray;
					&.not-users {
						display: flex;
						align-items: center;
						justify-content: center;
						svg {
							height: 14px;
							width: auto;
						}
					}
				}
			}
		}
	}
	.column-name {
		flex: 1 1 auto;
		&.link {
			cursor: pointer;
		}
	}
	.column-users {
		flex: 0 0 200px;
	}
	.column-tasks {
		flex: 0 0 150px;
	}
	.column-last-update {
		flex: 0 0 120px;
	}
	.column-progress {
		flex: 0 0 80px;
	}
	.column-user {
		flex: 0 0 90px;
		display: flex;
		justify-content: flex-end;
	}
}
.dropdown-item {
	&.disabled {
		color: #afb1b8 !important;
	}
}
</style>
